
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>酒店行业解决方案</h3>
      <p>
        通过全渠道触达能力，帮助品牌零售企业建立“商品+权益+会员”的组合式打法，帮助酒店行业构建以客户为中心的私域流量运营体系，有效提升续卡率、会员消费占比和流量运营效率，实现酒店的数字化营销转型。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <!-- 三大问题 -->
    <div class="problem">
      <h6>酒店行业面临的三大问题</h6>
      <div class="problem_c">
        <div class=" choice" >
          <img src="../../assets/images/hotel01.png" alt="" />
          <p>渠道费用高</p>
        </div>
        <div class="choice" >
          <img src="../../assets/images/cost02.png" alt="" />
          <p>自有渠道占比低</p>
        </div>
        <div class="choice" >
          <img src="../../assets/images/hotel02.png" alt="" />
          <p>会员运营能力弱</p>
        </div>
      </div>
    </div>
    <div class="operate">
      <div class="title">
        <p>全渠道客户运营体系</p>
        <p></p>
      </div>
      <div class="operate_c">
        <div>
          <h6>
            <span>1</span>
            私域＋公域
          </h6>
          <p>建立跨越公域和私域流量的全渠道运营机制，实现公私域流量互导</p>
        </div>
        <div>
          <h6>
            <span>3</span>
            精准用户洞察
          </h6>
          <p>建立CDP客户数据平台，获得消费者360°画像，精准识别客户</p>
        </div>
      </div>
      <div class="operate_c">
        <div>
          <h6>
            <span>2</span>
            线上＋线下
          </h6>
          <p>打通线上和线下渠道，对全渠道客户及会员进行统一管理和运营</p>
        </div>
        <div>
          <h6>
            <span>4</span>
            精准预测推荐
          </h6>
          <p>一站式AI智能推荐引擎，对消费者商品偏好进行精准预测和推荐</p>
        </div>
      </div>
    </div>
    <!-- 一体化 -->
    <div class="system">
      <h6>一体化会员中台系统</h6>
      <div>
        <p>新会员促进首购</p>
        <p>会员个性化场景营销</p>
        <p>会员生命周期自动化维护</p>
        <p>全渠道会员增长</p>
        <p>会员画像体系</p>
        <p>会员标签体系</p>
        <p>会员积分管理和运营</p>
        <p>会员权益体系</p>
      </div>
    </div>
    <!--方案 -->
    <div class="programme">
      <h6>解决方案</h6>
      <div>
        <p>
          建立CDP平台，聚合微信、会员、交易等各渠道数据形成全域消费者画像。基于数据洞察之上进行个性化精准触达，提升用户体验，刺激消费转化。
        </p>
        <p>
          会员营销活动多元化，精细化客户画像，实现精准人群触达为不同会员推送个性化内容和商品信息。
        </p>
        <p>
          全渠道参与会员生命周期管理，自动流程场景工具提升营销效率，丰富营销活动，加强与消费者的互动，增加黏性。
        </p>
      </div>
    </div>
    <!-- 成效 -->
    <div class="results">
      <h6>项目成效</h6>
      <div class="results_c">
        <div>
          <p>
            <img src="../../assets/images/douhao.png" />
            <span
              >会员续卡率提升：通过自动化续卡提醒流程，会员月度续卡率平均提升3%~5%。</span
            >
          </p>
          <p>
            <img src="../../assets/images/douhao.png" />
            <span
              >复购率提升：通过自动化促活流程+智能推荐，全渠道复购率显著提升。</span
            >
          </p>
        </div>
        <p class="results_l">
          <img src="../../assets/images/douhao.png" />
          <span
            >运营数据即时复盘：全触点行为数据时时回流DM
            Hub，运营人员可自行通过多种组合条件即时生成活动漏斗分析、用户行为偏好分析、订单分析等数据看板，即时复盘，立即调整策略，提高转化。</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      problem: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onproblem(a) {
      this.problem = a;
    },
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/industry01.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
  text-align:left;
    width: 7.43rem;
    margin: 1em auto 0;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.problem {
  padding: 1.36rem 2.2rem 1rem;
  h6 {
    text-align: center;
    font-size: 0.28rem;
  }
  .problem_c {
    padding-top: 0.58rem;
    display: flex;
    justify-content: space-around;
    div {
      width: 4.8rem;
      height: 2.26rem;
      padding: 0.48rem 0;
      box-sizing: border-box;
      text-align: center;
      img {
        width: 0.76rem;
        height: 0.76rem;
      }
      p {
        font-size: 0.22rem;
        color: #333;
        font-weight: 600;
      }
    }
    .choice:hover {
      transition: all 1.5s;
      border-radius: 0.08rem;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
    }
  }
}
.operate {
  width: 14.4rem;
  margin: 0 auto;
  background-color: #fffff5;
  padding: 0.57rem 0;
  display: flex;
  .title {
    width: 4rem;
    height: 3.4rem;
    border-right: 0.02rem solid #ffc53d;
    p:first-child {
      height: 1.7rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      font-weight: 600;
      margin: 0 0.06rem 0 0.4rem;
      text-align: center;
      border-bottom: 0.02rem solid #ffc53d;
      padding-top: 1.15rem;
    }
  }
  .operate_c {
    width: 4.8rem;
    height: 3.4rem;
    border-right: 0.02rem solid #ffc53d;
    div:first-child {
      height: 1.7rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      margin: 0 0.06rem 0 0.4rem;
      padding-top: 0.3rem;
      border-bottom: 0.02rem solid #ffc53d;
      h6 {
        font-weight: 600;
        font-size: 0.22rem;
        span {
          display: inline-block;
          width: 0.32rem;
          height: 0.32rem;
          background-color: #ffc53d;
          color: #fff;
          text-align: center;
          line-height: 0.32rem;
          margin-right: 0.1rem;
        }
      }
      p {
        padding-top: 0.18rem;
        padding-right: 0.5rem;
        font-size: 0.18rem;
        line-height: 0.27rem;
      }
    }
    div:nth-child(2) {
      height: 1.7rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      margin: 0 0.06rem 0 0.4rem;
      padding-top: 0.3rem;
      h6 {
        font-weight: 600;
        font-size: 0.22rem;
        span {
          display: inline-block;
          width: 0.32rem;
          height: 0.32rem;
          background-color: #ffc53d;
          color: #fff;
          text-align: center;
          line-height: 0.32rem;
          margin-right: 0.1rem;
        }
      }
      p {
        padding-top: 0.18rem;
        padding-right: 0.5rem;
        font-size: 0.18rem;
        line-height: 0.27rem;
      }
    }
  }
}
.system {
  padding: 1rem 2.2rem 1.36rem;
  h6 {
    font-size: 0.28rem;
    text-align: center;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 0.58rem;
    p {
      font-size: 0.22rem;
      font-weight: 600;
      line-height: 0.94rem;
      width: 3.3rem;
      height: 0.94rem;
      box-sizing: border-box;
      padding-left: 0.6rem;
      background-image: url(../../assets/images/system_bj.png);
      background-size: 100% 100%;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      margin: 0 0.4rem 0.45rem 0;
      border-radius: 0.1rem;
    }
  }
}
.programme {
  height: 4.34rem;
  background-image: url(../../assets/images/programme_bj.png);
  background-size: 100% 100%;
  h6 {
    color: #fff;
    font-size: 0.28rem;
    text-align: center;
    padding-top: 0.68rem;
  }
  div {
    display: flex;
    padding: 1.5rem 0 0 3.46rem;
    p {
      font-size: 0.18rem;
      width: 3.2rem;
      padding-right: 1.34rem;
      line-height: 0.27rem;
      color: #333;
      display: flex;
    }
  }
}
.results {
  h6 {
    color: #333;
    font-size: 0.28rem;
    text-align: center;
    padding: 0.58rem 0 0.92rem;
  }
  .results_c {
    padding: 0 1.75rem;
    div {
      font-size: 0.18rem;
      color: #fff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.42rem;
      p {
        img {
          width: 1rem;
          height: 1.18rem;
        }
        width: 7.52rem;
        height: 1.18rem;
        background-color: #333;
        line-height: 1.18rem;
        border-radius: 0.08rem;
        display: flex;
        padding-left: 0.2rem;
      }
    }
    .results_l {
      height: 1.5rem;
      box-sizing: border-box;
      display: flex;
      padding: 0.53rem 0.4rem 0.43rem 1.2rem;
      color: #fff;
      font-size: 0.18rem;
      background-color: #333;
      border-radius: 0.08rem;
      margin-bottom: 1.2rem;
      line-height: 0.27rem;
      position: relative;
      img {
        position: absolute;
        top: 0.16rem;
        left: 0.2rem;
        width: 1rem;
        height: 1.18rem;
      }
    }
  }
}
</style>
